import React from "react";
import "./apartman.css";
import Dezsafurdo from "./fotok/dezsa.jpg";
import Télikép from "./fotok/telen.jpg";
import Nyárikép from "./fotok/nyaron.jpg";
import { useNavigate } from "react-router-dom";


export function Apartman() {
  const navigate = useNavigate();


  return (
    <div className="egesz-container">
      <div className="szoveg-container">
        <h3>Nyitva az év minden napján</h3>
        {/*<button>View all rooms</button>*/}
      </div>
      <div className="apartman-container">
        <div className="room-container">
          <div className="kep-container" style={{ backgroundImage: `url(${Télikép})` }}>
            <div className="ar-container">36.000ft / éj</div>
          </div>
          <div className="room-details">
            <h4>Kuckozz be és élvezd a téli dézsa melegét!</h4>
            {/*<p>2 Sleeps &bull; 1 bunk bed</p>*/}
            <a href="#">Tovább</a>
          </div>
        </div>
        <div className="room-container">
          <div className="kep-container" style={{ backgroundImage: `url(${Nyárikép})` }}>
            <div className="ar-container">36.000ft / éj</div>
          </div>
          <div className="room-details">
            <h4>Legyél közel a természethez, és hűtsd le magad a nagy melegben!</h4>
            {/*<p>2 Sleeps &bull; 2 twin beds</p>*/}
            <a href="#">Tovább</a>
          </div>
        </div>
        <div className="kedvezmeny-container">
          <h4>Maradj tovább, spórolj többet!</h4>
          <p>Egyszerű: minél tovább maradsz, annál többet spórolsz!</p>
          <ul>
            <li>Spórolj akár 30%-ot a napi árból, ha több mint 14 éjszakát maradsz</li>
            <li>Spórolj akár 20%-ot az éjszakai árból, ha 7-14 éjszakát maradsz</li>
          </ul>
          <button onClick={(e)=>{e.preventDefault(); 
            navigate("/foglalas");
          }}>Foglalok!</button>
        </div>
      </div>
    </div>
  );
}
