import "./youneed.css";
import Dronevideo from "./lipicspartdrone.mp4";
import Legkondi from "./fotok/iconok/air-conditioner.png";
import Hegymaszas from "./fotok/iconok/hiking.png";
import Parkolas from "./fotok/iconok/parking.png";
import Dézsafürdő from "./fotok/iconok/hot-bath.png";
import { useNavigate } from "react-router-dom";



export function YouNeed(){
    const navigate = useNavigate();

    return(
        <div className="front-containerr">
            <div className="front-szovegg">
                <h1>Kikapcsolodás a természet közelében.</h1>
                <div className="p-containerr">
                    <p>A pihenésed során mindent megteszünk, hogy zavartalan és kellemes legyen az itt tartózkodásod. Vendégházunk a természet közvetlen közelében helyezkedik el, ahol a racka juhok közelsége különleges élményt nyújt.</p>
                </div> 
                <div className="icons-container">
                    <div className="icon-container">
                        <img src={Legkondi} />
                        {/*<p>Légkondíció</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Dézsafürdő} />
                        {/*<p>Dézsafürdő</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Parkolas} />
                        {/*<p>Parkolási lehetőség</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Hegymaszas} />
                        {/*<p>Kirándulási lehetőségek</p>*/}
                    </div>
                </div> 
                <button onClick={(e)=>{e.preventDefault(); 
            navigate("/foglalas");
          }} className="foglalok-button">Foglalok most!</button>        
            </div>
            <div className="front-video">
                <video autoPlay loop muted>
            <source src={Dronevideo} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            </div>
        </div>);
}