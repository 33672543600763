import "./videobackground.css";
import React from "react";
import lipicslogo from "./lipicspartlogo-removebg-preview.png"
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Home } from "../home";
import { Foglalas } from "../../foglalas/Foglalas";
import Login from "../adminpage/login";
import Admin from "../admin";
import { useRef } from "react";
import { useEffect } from "react";
import { TypeAnimationto } from "./typeanimation";

export function Header(){

    return(
        <nav>
            <div className="head">
            <Link to="/"><img className="lipicslogo" src={lipicslogo}></img></Link>
        <ul className="header-bar">
          
          <li className="menu">
            <Link to="/foglalas">Foglalás</Link>
          </li>
          {/*<li className="menu">
            <Link to="/login">Login</Link>
          </li>
          <li className="menu">
            <Link to="/admin">Admin</Link>
          </li>
          <li>
          {<button>Foglalok most!</button>}
          </li>*/}
        </ul>
        </div>
      </nav>
    );
}