import { configureStore, createSlice } from '@reduxjs/toolkit';


const initialState = {
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
  formData: {
    name: '',
    email: '',
    phone: '',
    guests: '',
    pet: '',
    pettype: '',
    pet_body_type: '',
    fizetendo: '0',
    paymentMethod: ''
  }
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setDates(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    }
  }
});

export const { setDates, setFormData } = bookingSlice.actions;

const store = configureStore({
  reducer: {
    booking: bookingSlice.reducer
  }
});

export default store;
