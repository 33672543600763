import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Home } from './pages/home';
import './App.css';
import { Foglalas } from './foglalas/Foglalas';
import Login from './pages/adminpage/login';
import Admin from './pages/admin';
import { Header } from './pages/homepage/header';
import { Foglalas_Pay } from './foglalas/foglalas_pay';
import { Koszonjuk } from './foglalas/koszonjuk';
import { KoszonjukBank } from './foglalas/koszonjukbankcard';
import { KoszonjukSZEP } from './foglalas/koszonjukszep';

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {
  const location = useLocation();

  return (
    <div>
      {/* Feltételesen jelenítjük meg a navigációs sávot */}
      {location.pathname !== '/' && (
        <Header />
      )}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/foglalas" element={<Foglalas />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/foglalas_pay" element={<Foglalas_Pay />} />
        <Route path="/koszonjuk" element={<Koszonjuk />} />
        <Route path="/koszonjuk/bankkartya" element={<KoszonjukBank />} />
        <Route path="/koszonjuk/szepkartya" element={<KoszonjukSZEP />} />
      </Routes>
    </div>
  );
}

export default App;
